<template>
  <div>
    <input
      id="form__complianceSmsMarketing"
      v-model="complianceSmsMarketing"
      class="form-check-input"
      name="complianceSmsMarketing"
      type="checkbox">
    <label
      class="form-check-label"
      for="form__complianceSmsMarketing"
      v-html="$options.filters.prepositions($t('globals.checkboxes.complianceSmsMarketing'))"/>
  </div>
</template>

<script>
export default {
  computed: {
    complianceSmsMarketing: {
      get() {
        return this.$store.state.cart.input.complianceSmsMarketing
      },
      set(value) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'complianceSmsMarketing', value: value })
      },
    },
  },
}
</script>
